import React from 'react'
import CustomerHeader from '../Header'
import Footer from '../Footer'
import SearchFilter from '../../../components/SearchFilter'

export default function CustomSearch() {
    return (
        <div>
            <CustomerHeader />
            <section className="py-5 title-styled customersearch-sec">
                <div className="container pt-5">
                    <div className="row d-none">
                        <div className="col-lg-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Library</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6">
                            <span>Sort by:</span> <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Featured</button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">Featured 123</a></li>
                                    <li><a className="dropdown-item" href="#">Featured 123</a></li>
                                    <li><a className="dropdown-item" href="#">Something</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-lg-3">
                            <div className="accordion" id="accordionCustomerSearch">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Categories</button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <ul className="withchexks">
                                                    <li>Islamic Education <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Tutoring <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Baby Sitting <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>In-House chefs <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">Ratings</button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <ul className="profile-stars">
                                                    <li><i className="fa fa-star" /></li>
                                                    <li><i className="fa fa-star" /></li>
                                                    <li><i className="fa fa-star" /></li>
                                                    <li><i className="fa fa-star" /></li>
                                                    <li><i className="fa fa-star" /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">Nationality</button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <ul className="withchexks">
                                                    <li>Canadian <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">Religion</button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <ul className="withchexks">
                                                    <li>Islam <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Christianity <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Judaism <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Hinduism <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">Select Availability</button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse show" data-bs-parent="#accordionCustomerSearch">
                                        <div className="accordion-body">
                                            <div className="widget-accords">
                                                <div className="fv-outer">
                                                    <input type="text" className="form-control f-v calander-icon calander-icon-rgt" placeholder="Monday, Aug 24" />
                                                </div>
                                                <label htmlFor="name" className="form-label fv-label mt-5">Set Time</label>
                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <div className="fv-outer">
                                                            <select className="form-select f-v">
                                                                <option>10:00 Am</option>
                                                                <option>10:00 Am</option>
                                                                <option>10:00 Am</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-md-0 mt-3">
                                                        <div className="fv-outer">
                                                            <select className="form-select f-v">
                                                                <option>10:00 Am</option>
                                                                <option>10:00 Am</option>
                                                                <option>10:00 Am</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label htmlFor="name" className="form-label fv-label d-block repeat-check">Repeat
                                                    <div className="form-check form-switch pull-right">
                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked />
                                                    </div>
                                                </label>
                                                <ul className="withchexks">
                                                    <li>Sunday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Monday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Tuesday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Wednesday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Thursday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Friday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                    <li>Saturday <input className="form-check-input" type="checkbox" defaultValue /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <SearchFilter />
                            <div className="profile-box px-5 py-4">
                                <div className="row pt-2">
                                    <div className="col-lg-6 my-3">
                                        <div className="inner-profile">
                                            <div className="d-flex">
                                                <div className="profile-img" style={{ backgroundImage: `url(/assets/img/t-1.png)` }} />
                                                <div className="profile-detail ms-3 flex-grow-1">
                                                    <div className="chat-icon" />
                                                    <h4>Hannah F.</h4>
                                                    <p>Nanny, Baby care</p><p>
                                                    </p><ul className="profile-stars">
                                                        <li><span>4.1</span></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                    </ul>
                                                    <p>5 years of Experience<br />English, Hindi<br />35 years old, Canadian</p>
                                                    <button type="submit" className="btn btn-primary btn-1">View Details <i className="fa fa-arrow-circle-right ms-2" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-3">
                                        <div className="inner-profile">
                                            <div className="d-flex">
                                                <div className="profile-img" style={{ backgroundImage: `url(/assets/img/t-1.png)` }} />
                                                <div className="profile-detail ms-3 flex-grow-1">
                                                    <div className="chat-icon" />
                                                    <h4>Hannah F.</h4>
                                                    <p>Nanny, Baby care</p><p>
                                                    </p><ul className="profile-stars">
                                                        <li><span>4.1</span></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                    </ul>
                                                    <p>5 years of Experience<br />English, Hindi<br />35 years old, Canadian</p>
                                                    <button type="submit" className="btn btn-primary btn-1">View Details <i className="fa fa-arrow-circle-right ms-2" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 my-3">
                                        <div className="inner-profile">
                                            <div className="d-flex">
                                                <div className="profile-img" style={{ backgroundImage: `url(/assets/img/t-1.png)` }} />
                                                <div className="profile-detail ms-3 flex-grow-1">
                                                    <div className="chat-icon" />
                                                    <h4>Hannah F.</h4>
                                                    <p>Nanny, Baby care</p><p>
                                                    </p><ul className="profile-stars">
                                                        <li><span>4.1</span></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                    </ul>
                                                    <p>5 years of Experience<br />English, Hindi<br />35 years old, Canadian</p>
                                                    <button type="submit" className="btn btn-primary btn-1">View Details <i className="fa fa-arrow-circle-right ms-2" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-3">
                                        <div className="inner-profile">
                                            <div className="d-flex">
                                                <div className="profile-img" style={{ backgroundImage: `url(/assets/img/t-1.png)` }} />
                                                <div className="profile-detail ms-3 flex-grow-1">
                                                    <div className="chat-icon" />
                                                    <h4>Hannah F.</h4>
                                                    <p>Nanny, Baby care</p><p>
                                                    </p><ul className="profile-stars">
                                                        <li><span>4.1</span></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                    </ul>
                                                    <p>5 years of Experience<br />English, Hindi<br />35 years old, Canadian</p>
                                                    <button type="submit" className="btn btn-primary btn-1">View Details <i className="fa fa-arrow-circle-right ms-2" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 my-3">
                                        <div className="inner-profile">
                                            <div className="d-flex">
                                                <div className="profile-img" style={{ backgroundImage: `url(/assets/img/t-1.png)` }} />
                                                <div className="profile-detail ms-3 flex-grow-1">
                                                    <div className="chat-icon" />
                                                    <h4>Hannah F.</h4>
                                                    <p>Nanny, Baby care</p><p>
                                                    </p><ul className="profile-stars">
                                                        <li><span>4.1</span></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                    </ul>
                                                    <p>5 years of Experience<br />English, Hindi<br />35 years old, Canadian</p>
                                                    <button type="submit" className="btn btn-primary btn-1">View Details <i className="fa fa-arrow-circle-right ms-2" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-3">
                                        <div className="inner-profile">
                                            <div className="d-flex">
                                                <div className="profile-img" style={{ backgroundImage: `url(/assets/img/t-1.png)` }} />
                                                <div className="profile-detail ms-3 flex-grow-1">
                                                    <div className="chat-icon" />
                                                    <h4>Hannah F.</h4>
                                                    <p>Nanny, Baby care</p><p>
                                                    </p><ul className="profile-stars">
                                                        <li><span>4.1</span></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                    </ul>
                                                    <p>5 years of Experience<br />English, Hindi<br />35 years old, Canadian</p>
                                                    <button type="submit" className="btn btn-primary btn-1">View Details <i className="fa fa-arrow-circle-right ms-2" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-lg-6 my-3">
                                        <div className="inner-profile">
                                            <div className="d-flex">
                                                <div className="profile-img" style={{ backgroundImage: `url(/assets/img/t-1.png)` }} />
                                                <div className="profile-detail ms-3 flex-grow-1">
                                                    <div className="chat-icon" />
                                                    <h4>Hannah F.</h4>
                                                    <p>Nanny, Baby care</p><p>
                                                    </p><ul className="profile-stars">
                                                        <li><span>4.1</span></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                    </ul>
                                                    <p>5 years of Experience<br />English, Hindi<br />35 years old, Canadian</p>
                                                    <button type="submit" className="btn btn-primary btn-1">View Details <i className="fa fa-arrow-circle-right ms-2" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-3">
                                        <div className="inner-profile">
                                            <div className="d-flex">
                                                <div className="profile-img" style={{ backgroundImage: `url(/assets/img/t-1.png)` }} />
                                                <div className="profile-detail ms-3 flex-grow-1">
                                                    <div className="chat-icon" />
                                                    <h4>Hannah F.</h4>
                                                    <p>Nanny, Baby care</p><p>
                                                    </p><ul className="profile-stars">
                                                        <li><span>4.1</span></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                        <li><i className="fa fa-star" /></li>
                                                    </ul>
                                                    <p>5 years of Experience<br />English, Hindi<br />35 years old, Canadian</p>
                                                    <button type="submit" className="btn btn-primary btn-1">View Details <i className="fa fa-arrow-circle-right ms-2" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </div >

    )
}
