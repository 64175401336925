import React from 'react'

export default function Question() {
    return (
        <div className="container">
            <section className="bg-1 cta-1 title-styled p-5 text-center text-lg-start">
                <div className="row">
                    <div className="col-lg-5">
                        <h2 className="text-white">Have a question?</h2>
                    </div>
                    <div className="col-lg-7">
                        <p className="text-white">
                            Contact the WithSabr team with questions about our services.
                        </p>
                        <button
                            type="button"
                            className="btn btn-primary btn-3 mt-3"
                            data-bs-toggle="modal"
                            data-bs-target="#contactus"
                        >
                            Contact Us <i className="fa fa-arrow-circle-right ms-2" />
                        </button>
                    </div>
                </div>
            </section>
        </div>
    )
}
