import React, { useEffect, useState } from 'react'
import CustomerHeader from '../Header'
import Footer from '../Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import apiClient from '../../../shared/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { getCities } from '../../../redux/reducers/homeSlice';

export default function FinancialInfo() {
    const payload = useLocation().state;
    const [verificationDocument, setVerificationDocument] = useState();
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        AccountTitle: '',
        AccountNumber: '',
        RoutingNumber: '',
        MailingAddress: '',
        ZipPostalCode: '',
        PermanentAddress: '',
        City: ''
    })
    const [errors, setErrors] = useState({})
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { cities } = useSelector(state => state.home)

    useEffect(() => {
        dispatch(getCities(1, 10))
    }, [])

    const handleSave = (e) => {
        e.preventDefault();
        const data = {
            ...payload, ...formData, VerificationDocument: {
                file: verificationDocument,
                fileName: verificationDocument?.name
            }
        }
        navigate('/background-check', { state: data })
        // setLoading(true)
        // apiClient.post('/Auth/VendorAccountRequest', data, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     }
        // }).then((res) => {
        //     console.log('ddddddddd', res.data)
        //     navigate('/financial-info')
        //     setLoading(false)
        // }).catch((err) => {
        //     setLoading(false)
        //     if (err.response?.data?.errors) {
        //         setErrors()
        //     }
        // })
    }


    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )

    return (
        <>
            <CustomerHeader />
            <section className="py-5 title-styled main-vendor">
                <section className="py-5">
                    <div className="heading-bordered mb-4 pb-1">
                        <div className="container pt-5">
                            <div className="row">
                                <div className="col-12">
                                    <h4>Financial Information</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <form onSubmit={handleSave}>

                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">First Name</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter first name"
                                            disabled={payload?.FullName}
                                            value={payload?.FullName && payload?.FullName?.toString()?.split(' ')[0]}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter last name"
                                            disabled={payload?.FullName}
                                            value={payload?.FullName && payload?.FullName?.toString()?.split(' ')[1]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Verify your identity</label>
                                        <div className="upload-container upload-container2 text-center">
                                            <label
                                                htmlFor="file-upload"
                                                className="upload-label upload-label2"
                                            >
                                                <span className="upload-icon upload-icon2 mb-3" />
                                                <span className="upload-caption upload-caption2 d-block mt-2">
                                                    Please upload a Driver's License or Valid Identification
                                                    photo.
                                                </span>
                                                <button
                                                    type="submit"
                                                    onClick={(e) => e.preventDefault()}
                                                    className="btn btn-primary btn-1 w-autostyle mt-4"
                                                >
                                                    <i className="fa fa-plus" />
                                                </button>
                                            </label>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                accept="image/*"
                                                className="upload-input"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    if (file) {
                                                        setVerificationDocument(file);
                                                    }
                                                }}
                                            />
                                            {verificationDocument && <div className='text-center'>{verificationDocument?.name}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Account Number</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your account number"
                                            name='AccountNumber'
                                            required
                                            value={formData.AccountNumber}
                                            onChange={(e) => setFormData({ ...formData, AccountNumber: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Account Title</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter account title"
                                            name='AccountTitle'
                                            required
                                            value={formData.AccountTitle}
                                            onChange={(e) => setFormData({ ...formData, AccountTitle: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Routing Number</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your routing number"
                                            required
                                            name='RoutingNumber'
                                            value={formData.RoutingNumber}
                                            onChange={(e) => setFormData({ ...formData, RoutingNumber: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Mailing Address</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter mailing address"
                                            required
                                            name='Mailing'
                                            value={formData.MailingAddress}
                                            onChange={(e) => setFormData({ ...formData, MailingAddress: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Zip Postal Code</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter zip postal code"
                                            required
                                            name='ZIP'
                                            value={formData.ZipPostalCode}
                                            onChange={(e) => setFormData({ ...formData, ZipPostalCode: e.target.value })}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">City</label>
                                        {/* <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter city"
                                            required
                                            name='City'
                                            value={formData.City}
                                            onChange={(e) => setFormData({ ...formData, City: e.target.value })}
                                        /> */}
                                        <select className="form-select f-v" onChange={(e) => {
                                            e.preventDefault();
                                            setFormData({ ...formData, City: e.target.value })
                                        }}>
                                            {cities?.map((city, index) => <option key={index} value={city.cityID}>{city.cityName}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Permanent Address</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your permeant address"
                                            required
                                            name='City'
                                            value={formData.PermanentAddress}
                                            onChange={(e) => setFormData({ ...formData, PermanentAddress: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center mt-5">
                                    <button type="submit" className="btn btn-primary btn-1" >
                                        {loading ? loader : <>Submit <i className="fa fa-arrow-circle-right ms-2" /></>}
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    )
}
