import React, { useEffect } from 'react'

export default function Seller() {
    useEffect(() => {
        const $ = window.jQuery;

        $('#reviews-carousel1').owlCarousel({
            center: false,
            items: 4,
            dots: true,
            loop: true,
            margin: 10,
            responsiveClass: true,
            nav: true,
            responsive: {
                0: {
                    items: 1
                },
                1200: {
                    items: 2
                }
            }


        });
    }, [])
    return (
        <section className="review-sec title-styled">
            <div className="container pb-5">
                <div className="row mb-4">
                    <div className="col-sm-12">
                        <h2>Top Rated Providers</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className="owl-carousel owl-theme cstm-theme-navs cstm-navs-center"
                            id="reviews-carousel1"
                        >
                            <div className="item">
                                <div className="review-box review-box d-flex flex-column flex-lg-row align-items-center">
                                    <div
                                        className="review-img"
                                        style={{ backgroundImage: `url(/assets/img/t-1.png)` }}
                                    />
                                    <div className="review-details">
                                        <h4>Hannah | Belfast</h4>
                                        <h4>
                                            4.8 <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star text-white ms-1" />
                                        </h4>
                                        <p>8 years experience</p>
                                        <button type="submit" className="btn btn-primary btn-1 mt-3">
                                            View Details <i className="fa fa-arrow-circle-right ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="review-box d-flex flex-column flex-lg-row align-items-center">
                                    <div
                                        className="review-img"
                                        style={{ backgroundImage: `url(/assets/img/t-1.png)` }}
                                    />
                                    <div className="review-details">
                                        <h4>Hannah | Belfast</h4>
                                        <h4>
                                            4.8 <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star text-white ms-1" />
                                        </h4>
                                        <p>8 years experience</p>
                                        <button type="submit" className="btn btn-primary btn-1 mt-3">
                                            View Details <i className="fa fa-arrow-circle-right ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="review-box d-flex flex-column flex-lg-row align-items-center">
                                    <div
                                        className="review-img"
                                        style={{ backgroundImage: `url(/assets/img/t-1.png)` }}
                                    />
                                    <div className="review-details">
                                        <h4>Hannah | Belfast</h4>
                                        <h4>
                                            4.8 <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star text-white ms-1" />
                                        </h4>
                                        <p>8 years experience</p>
                                        <button type="submit" className="btn btn-primary btn-1 mt-3">
                                            View Details <i className="fa fa-arrow-circle-right ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="review-box d-flex flex-column flex-lg-row align-items-center">
                                    <div
                                        className="review-img"
                                        style={{ backgroundImage: `url(/assets/img/t-1.png)` }}
                                    />
                                    <div className="review-details">
                                        <h4>Hannah | Belfast</h4>
                                        <h4>
                                            4.8 <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star text-white ms-1" />
                                        </h4>
                                        <p>8 years experience</p>
                                        <button type="submit" className="btn btn-primary btn-1 mt-3">
                                            View Details <i className="fa fa-arrow-circle-right ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
