import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getServices, searchCity } from '../redux/reducers/homeSlice';
import SearchableDropdown from './SearchableDropdown';
import apiClient from '../shared/apiClient';
import { useNavigate } from 'react-router-dom';

function SearchFilter() {
    const { services, searchCities } = useSelector(state => state.home)
    const [cityName, setCityName] = useState('');
    const [search, setSearch] = useState(true)

    const [cities, setCities] = useState([])

    const [formData, setFormData] = useState({
        ServiceId: '',
        cityId: null,
        ZipPostalCode: null,
        Distance: 1,
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setCities(searchCities)
    }, [searchCities])

    useEffect(() => {
        dispatch(getServices())
    }, [])
    useEffect(() => {
        const handler = setTimeout(() => {
            if (cityName) {
                setCities([])
                dispatch(searchCity({ searchText: cityName, countryName: null, pageNumber: 1, pageSize: 10 }));
            }
        }, 0);
        return () => {
            clearTimeout(handler);
        };
    }, [cityName, dispatch]);

    const handleSave = (e) => {
        e.preventDefault();
        // const formData = new FormData(e.target)
        // const data = Object.fromEntries(formData.entries())
        navigate('/custom-search')
        // apiClient.get('/Vendor/SearchVendorV1', {
        //     params: formData
        // }).then((res) => console.log('sssssss', res.data)).catch((err) => console.log('errrrrrrrrrr', err.response))



    }


    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )

    return (
        <>
            {/* Desktop Form */}
            <form className="form-banner d-lg-block d-none" onSubmit={handleSave}>
                <h3>Find Service</h3>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="fm-bnr-outer my-2">
                            <select className="form-select f-1" required name='ServiceId' onChange={(e) => setFormData({ ...formData, ServiceId: e.target.value })}>
                                {services.map((service, index) => <option key={index} value={service.ServiceID}>{service.ServiceName}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="fm-bnr-outer my-2">
                            <div className="row">
                                <div className="col-lg-3">
                                    <input
                                        type="text"
                                        className="form-control f-1"
                                        placeholder="City"
                                        style={{ position: 'relative' }}
                                        value={cityName}
                                        required
                                        name='cityId'
                                        onChange={(e) => {
                                            setSearch(true)
                                            setCityName(e.target.value)
                                        }}
                                    />
                                    {cityName && cities?.length > 0 && <div style={{ background: '#fff', width: '200px', position: 'absolute' }}>
                                        {cities.map((city, index) => <option
                                            key={index}
                                            value={city.cityID}
                                            style={{ cursor: 'pointer', height: '30px', borderBottom: '1px solid green', display: 'flex', alignItems: 'center' }}
                                            onClick={() => {
                                                setSearch(false)
                                                setCities([])
                                                setCityName(city.cityName)
                                                setFormData({ ...formData, cityId: city.cityID })
                                            }}
                                        >
                                            {city.cityName}
                                        </option>
                                        )}
                                    </div>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="input-group b-left">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fa fa-map-marker" />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control f-1"
                                            placeholder="Zip Code"
                                            name='ZipPostalCode'
                                            required
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => setFormData({ ...formData, ZipPostalCode: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="b-left">
                                        <select className="form-select f-1" name='Distance' required onChange={(e) => setFormData({ ...formData, Distance: parseInt(e.target.value) })}>
                                            <option value={1}>1 Miles</option>
                                            <option value={2}>2 Miles</option>
                                            <option value={3}>3 Miles</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 text-lg-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-1 mt-4 mt-lg-0"
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form class="form-banner d-block d-lg-none" onSubmit={handleSave}>
                <div className="bg-white px-3 py-4">
                    <h3>Find Service</h3>
                    <div className="row">
                        <div className="col-12 my-2">
                            <div className="fm-brdr">

                                <select className="form-select f-1" onChange={(e) => setFormData({ ...formData, ServiceId: e.target.value })}>
                                    {services.map((service, index) => <option key={index} value={service.ServiceID}>{service.ServiceName}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-12 my-2">
                            <div className="fm-brdr">
                                <input
                                    type="text"
                                    className="form-control f-1"
                                    placeholder="City"
                                    style={{ position: 'relative' }}
                                    value={cityName}
                                    onChange={(e) => {
                                        setSearch(true)
                                        setCityName(e.target.value)
                                    }}
                                />
                                {cityName && cities?.length > 0 && <div style={{ background: '#fff', width: '200px', position: 'absolute' }}>
                                    {cities.map((city, index) => <option
                                        key={index}
                                        value={city.cityID}
                                        style={{ cursor: 'pointer', height: '30px', borderBottom: '1px solid green', display: 'flex', alignItems: 'center' }}
                                        onClick={() => {
                                            setSearch(false)
                                            setCityName(city.cityName)
                                            setCities([])
                                            setFormData({ ...formData, cityId: city.cityID })
                                        }}
                                    >
                                        {city.cityName}
                                    </option>
                                    )}
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="fm-brdr my-2">
                        <div className="row">
                            <div className="col-6">
                                <select className="form-select f-1" onChange={(e) => setFormData({ ...formData, Distance: e.target.value })}>
                                    <option value={1}>1 Miles</option>
                                    <option value={2}>2 Miles</option>
                                    <option value={3}>3 Miles</option>
                                </select>
                            </div>
                            <div className="col-6 b-left">
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fa fa-map-marker" />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control f-1"
                                        placeholder="Zip Code"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setFormData({ ...formData, ZipPostalCode: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col my-2">
                            <button
                                type="submit"
                                className="btn btn-primary btn-1 btn-block w-100"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </form >
        </>
    )
}

export default SearchFilter
