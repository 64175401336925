import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../shared/apiClient';
import { getSessionItem, setSessionItem } from '../../shared/lsConfig';

export const getLogin = createAsyncThunk(
    'auth/login',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiClient.post('/Auth/login', payload);
            setSessionItem('auth_token', response.data?.token)
            setSessionItem('refresh_token', response.data?.refreshToken)
            setSessionItem('user_role', response.data?.userRole)
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const getRegister = createAsyncThunk(
    'auth/register',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiClient.post('/Auth/register', payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        loading: false,
        errMsg: null,
        token: null,
        message: null,
        isLogin: getSessionItem('auth_token') ? true : false,
        errors: []
    },
    reducers: {
        loginTrue: (state) => {
            state.isLogin = true
        },
        getLogout: (state) => {
            sessionStorage.clear();
            state.message = null;
            state.isLogin = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLogin.pending, (state) => {
                state.loading = true;
                state.errMsg = null;
                state.message = null
            })
            .addCase(getLogin.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload.token;
                state.message = action.payload.message;
                state.isLogin = true
            })
            .addCase(getLogin.rejected, (state, action) => {
                state.loading = false;
                state.errMsg = action.payload.message;
            });

        builder
            .addCase(getRegister.pending, (state) => {
                state.loading = true;
                state.errMsg = null;
                state.errors = []
            })
            .addCase(getRegister.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload;

            })
            .addCase(getRegister.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload.errors;
            });

    },
});
export const { getLogout, loginTrue } = authSlice.actions

export default authSlice.reducer;
