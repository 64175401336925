import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import CustomerHeader from '../Header'
import { useDispatch, useSelector } from 'react-redux'
import { getCities, getServices } from '../../../redux/reducers/homeSlice'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import apiClient from '../../../shared/apiClient'

export default function Vendor() {
    const [activeTab, setActiveTab] = useState('personal-info')
    const [profilePic, setProfilePic] = useState()
    const { services } = useSelector(state => state.home)

    const [experiences, setExperiences] = useState([])

    const [formData, setFormData] = useState({
        FullName: '',
        CityToServe: '',
        Distance: '',
        Gender: '',
        ContactInfo: '',
        MajorServiceId: '',
        SecondaryEmail: '',
        BriefIntro: '',
        HighestEducationLevel: '',
        DegreeFocus: '',
        Description: '',

    })



    const prevState = useLocation().state
    const navigate = useNavigate();

    const dispatch = useDispatch();

    let [searchParams, setSearchParams] = useSearchParams();
    const { cities } = useSelector(state => state.home)
    const [limit, setLimit] = useState(10)
    const [showEducation, setShowEducation] = useState(false)
    const [showEducationFocus, setShowEducationFocus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [error, setError] = useState();

    const educationOptions = [
        "Some Highschool",
        "Highschool Diploma/GED",
        "Associates Degree",
        "Bachelors Degree",
        "Masters Degree",
        "Doctorate Degree",
    ];
    const educationFocusOptions = [
        'Counseling',
        'Marriage and Family Therapy',
        'Early Childhood Education'
    ]

    useEffect(() => {
        setFormData({
            ...formData,
            ContactInfo: searchParams.get('number') != 'null' ? searchParams.get('number') : "",
            SecondaryEmail: searchParams.get('email') != 'null' ? searchParams.get('email') : "",
            FullName: searchParams.get('name') != 'null' ? searchParams.get('name') : "",
        })
    }, [searchParams])


    useEffect(() => {
        dispatch(getServices())
        dispatch(getCities(1, 10))
    }, [])


    const handleSave = (e) => {
        e.preventDefault();

        let payload = { ...formData, ProfieImage: profilePic, ExperienceDetails: experiences }
        navigate('/financial-info', { state: payload })
        // setLoading(true)
        // apiClient.post('/Auth/VendorAccountRequest', payload, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     }
        // }).then((res) => {
        //     console.log('ddddddddd', res.data)
        //     navigate('/financial-info')
        //     setLoading(false)
        // }).catch((err) => {
        //     setLoading(false)
        //     if (err.response?.data?.errors) {
        //         setErrors()
        //     }
        // })
    }

    const handleScroll = (event) => {
        const bottomReached = event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 10;

        if (bottomReached) {
            setLimit((prevLimit) => prevLimit + 10);
            dispatch(getCities(1, limit + 10));
        }
    };

    const validation = (errors = []) => {
        if (errors.length > 0) {
            return errors.map((err, index) => <p key={index} className='text-danger'>{err}</p>)
        }
    }


    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )

    return (
        <>
            <CustomerHeader />
            <section className="py-5 title-styled main-vendor">
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 text-center py-5">
                            <h2>Begin Building Your Provider Profile</h2>
                        </div>
                    </div>
                </div>
                <ul className="nav nav-tabs tabs-style1" id="myTab" role="tablist">
                    <li className="nav-item col-6" role="presentation" onClick={() => setActiveTab('personal-info')}>
                        <a
                            className={`nav-link d-flex justify-content-center ${activeTab == 'personal-info' && 'active'}`}
                            id="personal-info-tab"
                            style={{ cursor: 'pointer' }}
                            // data-bs-toggle="tab"
                            // href="#personal-info"
                            role="tab"
                            // aria-controls="personal-info"
                            aria-selected={activeTab == "personal-info"}
                        >
                            <i className="tik-icon me-2" />
                            Vendor Profile
                        </a>
                    </li>
                    <li className="nav-item col-6 " role="presentation" onClick={() => setActiveTab('business-info')}>
                        <a
                            className={`nav-link d-flex justify-content-center ${activeTab == 'business-info' && 'active'}`}
                            id="business-info-tab"
                            style={{ cursor: 'pointer' }}
                            // data-bs-toggle="tab"
                            // href="#business-info"
                            role="tab"
                            // aria-controls="business-info"
                            aria-selected={activeTab == "business-info"}
                        >
                            <i className="tik-icon me-2" />
                            Availability
                        </a>
                    </li>
                </ul>

                <form onSubmit={handleSave}>
                    <div className="tab-content mt-2" id="myTabContent">
                        {activeTab == "personal-info" && (
                            <div
                                className={`tab-pane fade show ${activeTab == 'personal-info' && 'active'}`}
                                id="personal-info"
                                role="tabpanel"
                                aria-labelledby="personal-info-tab"
                            >
                                <div className="container py-md-5 pb-3 pt-5">
                                    <div className="row">
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label htmlFor="name" className="form-label fv-label">
                                                    Full Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control f-v"
                                                    disabled={searchParams.get('name') !== 'null'}
                                                    value={formData.FullName}
                                                    placeholder="Enter Full Name"
                                                    name='FullName'
                                                    onChange={(e) => setFormData({ ...formData, FullName: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <div className="upload-container text-center">
                                                    <label htmlFor="file-upload" className="upload-label">
                                                        <span className="upload-icon" />
                                                        <span className="upload-caption d-block mt-2">
                                                            Add Profile Picture
                                                        </span>
                                                    </label>
                                                    <input
                                                        id="file-upload"
                                                        type="file"
                                                        accept="image/*"
                                                        className="upload-input"
                                                        name='profilePic'

                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            const file = e.target.files[0];
                                                            if (file) {
                                                                setProfilePic(file);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {profilePic && <div className='text-center'>{profilePic?.name}</div>}
                                                {errors?.ProfieImage && validation(errors?.ProfieImage)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control f-v"
                                                    placeholder="Enter your Email"
                                                    disabled={searchParams.get('email') !== 'null'}
                                                    value={formData.SecondaryEmail}
                                                    name='SecondaryEmail'
                                                    onChange={(e) => setFormData({ ...formData, SecondaryEmail: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Phone</label>
                                                <input
                                                    type="text"
                                                    className="form-control f-v"
                                                    placeholder="Phone number"
                                                    disabled={searchParams.get('number') !== 'null'}
                                                    value={formData.ContactInfo}
                                                    onChange={(e) => setFormData({ ...formData, ContactInfo: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Service Category</label>
                                                <select
                                                    className="form-select f-v"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setFormData({ ...formData, MajorServiceId: e.target.value })
                                                    }}
                                                >
                                                    {services?.map(service => <option
                                                        key={service.ServiceID}
                                                        value={service.ServiceID}
                                                    >
                                                        {service.ServiceName}
                                                    </option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Gender</label>
                                                <div className="d-flex">
                                                    <label className="align-items-center d-flex me-2">
                                                        <input
                                                            type="radio"
                                                            className="option-input radio"
                                                            name="gender"
                                                            defaultChecked=""
                                                            value={'M'}
                                                            onChange={(e) => setFormData({ ...formData, Gender: e.target.value })}
                                                        />
                                                        Male
                                                    </label>
                                                    <label className="align-items-center d-flex">
                                                        <input
                                                            type="radio"
                                                            className="option-input radio"
                                                            name="gender"
                                                            value={'F'}
                                                            onChange={(e) => setFormData({ ...formData, Gender: e.target.value })}
                                                        />
                                                        Female
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Brief Intro</label>
                                                <textarea
                                                    className="form-control f-v txtarea1"
                                                    placeholder="Describe yourself"
                                                    defaultValue={""}
                                                    onChange={(e) => setFormData({ ...formData, BriefIntro: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">City to Serve</label>
                                                <select className="form-select f-v" onScroll={handleScroll} onChange={(e) => {
                                                    e.preventDefault();
                                                    setFormData({ ...formData, CityToServe: e.target.value })
                                                }}>
                                                    {cities?.map((city, index) => <option key={index} value={city.cityID}>{city.cityName}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label className="form-label fv-label">Distance</label>
                                                <select className="form-select f-v" onChange={(e) => setFormData({ ...formData, Distance: e.target.value })}>
                                                    <option>Distance</option>
                                                    <option value={1}>1 Miles</option>
                                                    <option value={2}>2 Miles</option>
                                                    <option value={3}>3 Miles</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className="py-md-5 py-3">
                                    <div className="heading-bordered mb-4 pb-1">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4>Add Education</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label className="form-label fv-label"  >
                                                        Highest Level of Education
                                                    </label>
                                                    <div
                                                        className="form-select f-v"
                                                        role="button"
                                                        onClick={() => setShowEducation(!showEducation)}
                                                    >
                                                        {formData.HighestEducationLevel ? educationOptions[formData.HighestEducationLevel - 1] : "Select options"}
                                                    </div>
                                                    {showEducation && <div className="cdollapse">
                                                        <ul className="multiscrolllist">
                                                            {educationOptions.map((edu, idx) => (
                                                                <li
                                                                    style={{ cursor: 'pointer' }}
                                                                    key={idx}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setFormData({ ...formData, HighestEducationLevel: idx + 1 })
                                                                        setShowEducation(false)
                                                                    }}
                                                                >
                                                                    <i className="tik-icon me-2 " style={{ background: formData.HighestEducationLevel == idx + 1 && "#00CC66" }} />
                                                                    {edu}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label className="form-label fv-label">Degree Focus</label>
                                                    <div
                                                        className="form-select f-v"
                                                        role="button"
                                                        onClick={() => setShowEducationFocus(!showEducationFocus)}
                                                    >
                                                        {formData.DegreeFocus ? formData.DegreeFocus : "Select options"}
                                                    </div>
                                                    {showEducationFocus && <div className="cdollapse">
                                                        <ul className="multiscrolllist">
                                                            {educationFocusOptions.map((edu, idx) => (
                                                                <li
                                                                    style={{ cursor: 'pointer' }}
                                                                    key={idx}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setFormData({ ...formData, DegreeFocus: edu })
                                                                        setShowEducationFocus(false)
                                                                    }}
                                                                >
                                                                    <i className="tik-icon me-2 " style={{ background: formData.DegreeFocus == edu && "#00CC66" }} />
                                                                    {edu}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="py-md-5 py-3">
                                    <div className="heading-bordered mb-4 pb-1">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-10">
                                                    <h4>Add Experience</h4>
                                                </div>
                                                <div className='col-2 text-end'>
                                                    <button
                                                        className='btn'
                                                        style={{ color: '#00CC66' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setExperiences(prevState => [...prevState, {
                                                                company: '',
                                                                JobTitle: '',
                                                                experienceYears: '',
                                                                description: ''
                                                            }])
                                                        }}
                                                    >
                                                        <i className='fa fa-plus' />
                                                    </button>
                                                    {experiences.length > 0 && <button
                                                        className='btn'
                                                        style={{ color: 'red' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setExperiences((prevState) => prevState.slice(0, -1))
                                                        }}
                                                    >
                                                        <i className='fa fa-minus' />
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        {experiences.map((exp, index) => (<div key={index} className="row border-bottom">
                                            <div className="col-md-4 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label htmlFor="name" className="form-label fv-label">
                                                        Job Title
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control f-v"
                                                        placeholder="Enter Job Title"
                                                        name='JobTitle'
                                                        value={exp?.JobTitle}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            const updatedExperiences = [...experiences];
                                                            updatedExperiences[index] = {
                                                                ...updatedExperiences[index],
                                                                JobTitle: e.target.value,
                                                            };
                                                            setExperiences(updatedExperiences);
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label htmlFor="name" className="form-label fv-label">
                                                        Company Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control f-v"
                                                        placeholder="Enter Company Name"
                                                        name='CompanyName'
                                                        value={exp.company}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            const updatedExperiences = [...experiences];
                                                            updatedExperiences[index] = {
                                                                ...updatedExperiences[index],
                                                                company: e.target.value,
                                                            };
                                                            setExperiences(updatedExperiences);
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label htmlFor="name" className="form-label fv-label">
                                                        Experience
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control f-v"
                                                        placeholder="Enter Experience"
                                                        name='Experience'
                                                        value={exp.experienceYears}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            const updatedExperiences = [...experiences];
                                                            updatedExperiences[index] = {
                                                                ...updatedExperiences[index],
                                                                experienceYears: e.target.value,
                                                            };
                                                            setExperiences(updatedExperiences);
                                                        }}


                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label className="form-label fv-label">
                                                        Please describe your experience
                                                    </label>
                                                    <textarea
                                                        className="form-control f-v txtarea1"
                                                        placeholder="Describe your experience"
                                                        // defaultValue={""}
                                                        value={exp.description}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            const updatedExperiences = [...experiences];
                                                            updatedExperiences[index] = {
                                                                ...updatedExperiences[index],
                                                                description: e.target.value,
                                                            };
                                                            setExperiences(updatedExperiences);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>))}
                                    </div>
                                </section>
                            </div>
                        )}

                        {activeTab == "business-info" && (
                            <div
                                className={`tab-pane fade show ${activeTab == 'business-info' && 'active'}`}
                                id="business-info"
                                role="tabpanel"
                                aria-labelledby="business-info-tab"

                            >
                                <div className="container py-5">
                                    <div className="row">
                                        <div className="col-md-6 my-md-4 my-3">
                                            <div className="fv-outer">
                                                <label htmlFor="name" className="form-label fv-label">
                                                    Please Select Availability
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control f-v calander-icon"
                                                    placeholder="Select work schedule"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="fv-outer">
                                                <div className="calendar-cstm">
                                                    <div className="container">
                                                        <div className="row calendartop align-items-center text-center">
                                                            <div className="col-md-4 offset-md-4">
                                                                <h4>
                                                                    <i className="fa fa-angle-left" /> JULY 2024{" "}
                                                                    <i className="fa fa-angle-right" />
                                                                </h4>
                                                            </div>
                                                            <div className="col-md-4 text-md-end mt-md-0 mt-4">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-1 radius-50"
                                                                >
                                                                    <i className="fa fa-plus me-1 fs-14" />
                                                                    Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-calendar">
                                                        <div className="thead">
                                                            <div className="d-flex justify-content-between">
                                                                <div>S</div>
                                                                <div>M</div>
                                                                <div>T</div>
                                                                <div>W</div>
                                                                <div>T</div>
                                                                <div>F</div>
                                                                <div>S</div>
                                                            </div>
                                                        </div>
                                                        <div className="tbody">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="daypass">27</div>
                                                                <div className="daypass">28</div>
                                                                <div className="daypass">29</div>
                                                                <div className="daypass">30</div>
                                                                <div>1</div>
                                                                <div>2</div>
                                                                <div>3</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>4</div>
                                                                <div>5</div>
                                                                <div>6</div>
                                                                <div className="dayselected">
                                                                    <span>7</span>
                                                                </div>
                                                                <div>8</div>
                                                                <div>9</div>
                                                                <div>10</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>11</div>
                                                                <div>12</div>
                                                                <div>13</div>
                                                                <div>14</div>
                                                                <div>15</div>
                                                                <div>16</div>
                                                                <div>17</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>18</div>
                                                                <div>19</div>
                                                                <div>20</div>
                                                                <div>21</div>
                                                                <div>22</div>
                                                                <div>23</div>
                                                                <div>24</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>25</div>
                                                                <div>26</div>
                                                                <div>27</div>
                                                                <div>28</div>
                                                                <div>29</div>
                                                                <div>30</div>
                                                                <div className="daypass">1</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center pt-4">
                                            <div className="col-md-6 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label htmlFor="name" className="form-label fv-label">
                                                        Add Availability
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control f-v calander-icon calander-icon-rgt"
                                                        placeholder="Select work schedule"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-5 offset-md-1 my-md-4 my-3">
                                                <label htmlFor="name" className="form-label fv-label">
                                                    Set Time
                                                </label>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="fv-outer">
                                                            <input
                                                                type="text"
                                                                className="form-control f-v calander-icon calander-icon-rgt"
                                                                placeholder="10:00 Am"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-md-0 mt-3">
                                                        <div className="fv-outer">
                                                            <input
                                                                type="text"
                                                                className="form-control f-v calander-icon calander-icon-rgt"
                                                                placeholder="11:00 Am"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center pt-4">
                                            <div className="col-md-6 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <label htmlFor="name" className="form-label fv-label d-block">
                                                        Recurring
                                                        <div className="form-check form-switch pull-right">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="flexSwitchCheckChecked"
                                                                defaultChecked=""
                                                            />
                                                        </div>
                                                    </label>
                                                    <ul className="dayselection">
                                                        <li>
                                                            <span>S</span>Sun
                                                        </li>
                                                        <li className="checkedday">
                                                            <span>M</span>Mon
                                                        </li>
                                                        <li>
                                                            <span>T</span>Tue
                                                        </li>
                                                        <li className="checkedday">
                                                            <span>W</span>Wed
                                                        </li>
                                                        <li>
                                                            <span>T</span>Thu
                                                        </li>
                                                        <li>
                                                            <span>F</span>Fri
                                                        </li>
                                                        <li>
                                                            <span>S</span>Sat
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-5 offset-md-1 my-md-4 my-3">
                                                <div className="fv-outer">
                                                    <input
                                                        type="text"
                                                        className="form-control f-v calander-icon calander-icon-rgt"
                                                        placeholder="Repeat until (Optional)"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12 text-center mt-5">
                                <button type="submit" className="btn btn-primary btn-1">
                                    {loading ? loader : <>
                                        Save &amp; Continue{" "}
                                        <i className="fa fa-arrow-circle-right ms-2" />
                                    </>}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section >
            <Footer />
        </>
    )
}
