import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AuthLayout from './AuthLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getRegister, loginTrue } from '../../../redux/reducers/authSlice';
import apiClient from '../../../shared/apiClient';
import axios from 'axios';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import toast from 'react-hot-toast';
import { setSessionItem } from '../../../shared/lsConfig';

function Signup() {
    const dispatch = useDispatch()
    const auths = useSelector(state => state.auths)
    const [loading, setLoading] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('+1')
    const [numberValidation, setNumberValidation] = useState(false)
    const [typePassword, setTypePassword] = useState(null)
    const [currentPassword, setCurrentPassword] = useState(null)
    const [passwordMatch, setPasswordMatch] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [errors, setErrors] = useState()
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        number: ''
    })

    const navigate = useNavigate();
    const prevState = useLocation().state


    useEffect(() => {
        if (!prevState?.userRole) navigate('/welcome-screen')
    }, [prevState])

    useEffect(() => {
        if (auths.isLogin) navigate(prevState?.userRole == "vendor" ? `/vendor?name=${userData.name}&email=${userData.email}&number=${userData.number}` : '/', { state: userData })
    }, [auths])


    const seperateName = (name = "") => {
        let data = { firstName: "", lastName: "" }
        const splitedName = name.split(' ')
        if (splitedName.length > 1) data = { firstName: splitedName[0], lastName: splitedName.slice(1)?.join(" ") }
        else data = { firstName: splitedName[0], lastName: "" }
        return data
    }

    const handleSignup = (e) => {
        e.preventDefault();
        setErrors(null)
        const formData = new FormData(e.target);

        const data = Object.fromEntries(formData.entries())

        const payload = { ...data, ...seperateName(data?.fullName), userRole: prevState?.userRole };
        setLoading(true)
        apiClient.post('/Auth/register', payload)
            .then((res) => {
                setLoading(false)
                navigate(`/otp-verification?type=email&number=${data.phoneNumber?.slice(2)}&email=${data.email}&role=${prevState?.userRole}&name=${data.fullName}`)
            }).catch(err => {
                if (err.response?.data?.errors) setErrors(err.response?.data?.errors)
                if (err.response.data?.message) setErrors(err.response?.data?.message)
                setLoading(false)
            })
        // dispatch(getRegister(payload))

        // if (serviceSeeker) navigate('/financial-info')
        // else navigate('/otp-verification')
    }

    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )

    const validation = (errors = []) => {
        if (errors.length > 0) {
            return errors.map((err, index) => <p key={index} className='text-danger'>{err}</p>)
        }
    }
    return (
        <AuthLayout>
            <div className="login-form-area px-3 px-lg-5">
                <h2>Create Account</h2>
                <ul className="socials-2 my-3">
                    <li>
                        <GoogleOAuthProvider clientId="317913382060-1df0u8uphbnv99rg66k7kc9s2rtt4cbf.apps.googleusercontent.com">
                            <GoogleLoginButton userRole={prevState?.userRole == 'customer' ? "Customer" : "Vendor"} setUserData={setUserData} />
                        </GoogleOAuthProvider>
                    </li>
                    <li>
                        <a href="#" target="_blank" className="fa fa-apple" />
                    </li>
                </ul>
                <p className="pt-4 mb-0 mb-md-2">
                    Or use your email for Registration!
                </p>
                <form className="py-3 px-lg-5" onSubmit={handleSignup}>
                    <div className="my-4" style={{ textAlign: 'left' }}>
                        <input
                            type="text"
                            className="form-control f-0"
                            placeholder="Full Name"
                            name='fullName'
                            required
                        />
                        {errors?.FirstName && validation(errors?.FirstName)}
                    </div>
                    <div className="my-4" style={{ textAlign: 'left' }}>
                        <input
                            type="text"
                            className="form-control f-0"
                            placeholder="Username"
                            name='username'
                            required
                        />
                        {errors?.Username && validation(errors?.Username)}
                    </div>
                    <div className="my-4" style={{ textAlign: 'left' }}>
                        <input
                            type="email"
                            className="form-control f-0"
                            placeholder="Email"
                            name='email'
                            required
                        />
                        {errors?.Email && validation(errors?.Email)}
                    </div>
                    <div className="my-4" style={{ textAlign: 'left' }}>
                        <input
                            type="text"
                            className="form-control f-0"
                            placeholder="Phone Number"
                            name='phoneNumber'
                            required
                            value={phoneNumber}
                            onChange={(e) => {
                                if (/^[\d-+]*$/.test(e.target.value)) {
                                    setNumberValidation(false);
                                    setPhoneNumber(e.target.value);
                                } else {
                                    setNumberValidation(true);
                                }
                            }}
                        />
                        {numberValidation && <p className='text-danger'>Only numbers are allowed!</p>}
                        {errors?.PhoneNumber && validation(errors?.PhoneNumber)}
                    </div>
                    <div className="my-4 icon-over" style={{ textAlign: 'left' }}>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="form-control f-0"
                            placeholder="Password"
                            name='password'
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                        <i
                            className={showPassword ? "fa fa-eye-slash icon-over" : "fa fa-eye icon-over"}
                            id="togglePassword1"
                            role='button'
                            onClick={() => setShowPassword(!showPassword)}
                        />
                        {errors?.Password && validation(errors?.Password)}
                    </div>
                    <div className="m4-4 icon-over" style={{ textAlign: 'left' }}>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control f-0"
                            id="confirmpass"
                            placeholder="Confirm Password"
                            name='confirm_password'
                            required
                            onChange={(e) => {
                                if (e.target.value === currentPassword) setPasswordMatch(true)
                                else setPasswordMatch(false)
                            }}
                        />
                        <i
                            className={showConfirmPassword ? "fa fa-eye-slash icon-over" : "fa fa-eye icon-over"}
                            id="togglePassword"
                            role='button'
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                        {!passwordMatch && <p className='text-danger'>The new password that you entered do not match!</p>}

                    </div>
                    <div className='mt-2' style={{ textAlign: 'left' }}>
                        {typeof errors !== "object" && <p className='text-danger'>{errors}</p>}
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary btn-1 d-block w-100 my-4"
                    >
                        {loading ? loader : "Register"}
                    </button>
                </form>
            </div>
        </AuthLayout>
    )
}


const GoogleLoginButton = ({ userRole, setUserData }) => {
    const dispatch = useDispatch();
    const handleLoginSuccess = async (tokenResponse, _) => {
        const token = tokenResponse.access_token;
        axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                const data = {
                    token: token,
                    email: res.data?.email,
                    provider: 'Google',
                    userRole: userRole
                }

                setUserData({
                    name: res.data?.name,
                    email: res.data?.email,
                    number: null
                })
                apiClient.post('/Auth/ThirdPartyLogin', data).then((res) => {
                    setSessionItem('auth_token', res.data?.token)
                    setSessionItem('refresh_token', res.data?.refreshToken)
                    setSessionItem('userRole', res.data?.userRole)
                    toast.success(res.data?.message)
                    dispatch(loginTrue())
                }).catch((err) => {
                    toast.error(err?.response?.data?.message || "Network Error!")
                })

            })
            .catch((err) => toast.error('Google Authentication Error'));

    }
    const handleLoginError = (error) => {
        console.log("Login Failed:", error);
    };

    const login = useGoogleLogin({
        onSuccess: handleLoginSuccess,
        onError: handleLoginError,
    });

    return <i role='button' onClick={login} className="fa fa-google" />;
};


export default Signup
