import React from 'react'

function Portfolio() {
    return (
        <>
            <section className="menus-sec pb-5 pt-3">
                <div className="container py-3 py-lg-5">
                    <div className="row mb-3">
                        <div className="col-lg-8 title-styled">
                            <h2>Safety is our first priority</h2>
                            <p className="lead text-color4">
                                WithSabr providers must participate in a required three-step safety evaluation.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="service-box-bottom">
                                <div
                                    className="sbox-img"
                                    style={{ backgroundImage: `url('/assets/img/s3.png')` }}
                                />
                                <h4>'SabrSafe' Screening</h4>
                                <p>
                                    At WithSabr, we meet with all service providers directly to screen
                                    for competency, core values, and safety.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box-bottom">
                                <div
                                    className="sbox-img"
                                    style={{ backgroundImage: `url('/assets/img/s3.png')` }}
                                />
                                <h4>Criminal Background Check</h4>
                                <p>
                                    All WithSabr providers must <br /> complete a required criminal  background check.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box-bottom">
                                <div
                                    className="sbox-img"
                                    style={{ backgroundImage: `url('/assets/img/s3.png')` }}
                                />
                                <h4>Driver's License Verification</h4>
                                <p>
                                    We verify each provider's driver's license <br />
                                    for your security.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* modal */}
            <div
                className="modal modal-contactus fade"
                id="contactus"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <div className="modal-body">
                            <div className="container-fluid title-styled">
                                <h2 className="mb-3 mb-lg-5 text-center">Contact us</h2>
                                <form>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="py-2 py-lg-3">
                                                <input
                                                    type="text"
                                                    className="form-control f-t"
                                                    placeholder="Your Name"
                                                />
                                            </div>
                                            <div className="py-2 py-lg-3">
                                                <input
                                                    type="email"
                                                    className="form-control f-t"
                                                    placeholder="Your Email"
                                                />
                                            </div>
                                            <div className="py-2 py-lg-3">
                                                <input
                                                    type="text"
                                                    className="form-control f-t"
                                                    placeholder="Phone"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="py-2 py-lg-3">
                                                <textarea
                                                    className="form-control f-t textara"
                                                    rows={5}
                                                    placeholder="Your Message"
                                                    defaultValue={""}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 mt-2">
                                        <div className="col text-end">
                                            <button type="submit" className="btn btn-primary btn-1">
                                                Submit <i className="fa fa-arrow-circle-right ms-2" />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio
