import React from 'react'
import { useNavigate } from 'react-router-dom'
import SearchFilter from '../../../components/SearchFilter';

function Banner({ services }) {
    const navigate = useNavigate();

    return (
        <section className="banner-sec title-styled">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 my-2">
                        {/* <p class="mb-0">Lorem ipsum dolar</p> */}
                        <h2>Trusted Services for Muslims, by Muslims</h2>
                        <p className="my-4">
                            Find home and family services WithSabr, where safety and personal values matter.
                        </p>
                        <button className="btn btn-primary btn-1" onClick={() => navigate('/about')}>
                            Explore More <i className="fa fa-arrow-circle-right ms-2" />
                        </button>
                    </div>
                    <div className="col-lg-6 my-2">
                        <img
                            src={'/assets/img/banner.png'}
                            alt="banner image"
                            className="img-fluid"
                        />
                    </div>
                </div>
                <div className="row align-items-center pt-5">
                    <div className="col">
                        <SearchFilter />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Banner
