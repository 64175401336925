import React, { useEffect } from 'react'
import CustomerHeader from '../Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux';
import { getServices } from '../../../redux/reducers/homeSlice';

export default function About() {

    const { services } = useSelector(state => state.home)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getServices())
    }, [])


    useEffect(() => {
        const $ = window.jQuery;
        $('#servicebox-carousel').owlCarousel({
            center: false,
            items: services.length ? services.length : 1,
            dots: false,
            loop: true,
            margin: 20,
            responsiveClass: true,
            nav: true,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1200: {
                    items: 4
                }
            }
        });

        return () => {
            $('#servicebox-carousel').owlCarousel('destroy');
        };

    }, [services]);

    const images = ['/assets/img/se-1.png', '/assets/img/se-2.png', '/assets/img/se-3.png', '/assets/img/se-1.png']

    return (
        <>
            <CustomerHeader />
            <section className="py-5 title-styled mt-3">
                <div className="container-fluid pt-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 p-0">
                            <div
                                className="full-height-img full-img-shade"
                                style={{ backgroundImage: 'url("/assets/img/ab-1.png")' }}
                            ></div>
                        </div>
                        <div className="col-md-6 position-relative">
                            <div className="center-content text-center">
                                <h2>This is WithSabr</h2>
                                <p>
                                    At WithSabr, we know that safety and values are important when seeking care for your family. With our platform,
                                    you can select from a variety of family and home services with confidence, knowing that WithSabr providers share your
                                    same values and have gone through a rigorous review process.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Banner End*/}
            <section className="pb-5 title-styled">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 position-relative">
                            <div className="center-content text-md-start text-center">
                                <h2>Who we are?</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Pellentesque vel nisi nec ligula lobortis sollicitudin.
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Pellentesque.
                                </p>
                                <ul className="list-mv mt-5">
                                    <li>
                                        <img src="/assets/img/mark-arrow.png" className="img-fluid" />
                                        Seek Sevices{" "}
                                        <span>
                                            <i className="fa fa-angle-right" />
                                        </span>
                                    </li>
                                    <li>
                                        <img src="/assets/img/light-icon.png" className="img-fluid" />
                                        Provide Services{" "}
                                        <span>
                                            <i className="fa fa-angle-right" />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div
                                className="full-height-img"
                                style={{ backgroundImage: 'url("/assets/img/ab-3.png")' }}
                            ></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="menus-sec py-5">
                <div className="container py-3 py-lg-5">
                    <div className="row mb-4">
                        <div className="col title-styled">
                            <div className="border-styled pb-2">
                                <h2>Popular Categories</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div
                                className="owl-carousel owl-theme cstm-theme-navs cstm-navs-center"
                                id="servicebox-carousel"
                            >
                                {services.map((service, index) => (
                                    <div className="item" id='category-card' key={index}>
                                        <div className="service-box">
                                            <img
                                                src={images[Math.floor(Math.random() * images.length)]}

                                                alt="img"
                                                className="img-fluid"
                                            />
                                            <h4>{service.ServiceName}</h4>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="bg-5 py-5">
                <div className="container py-3 py-lg-5">
                    <div className="row">
                        <div
                            className="col-md-3 px-0 bg-fulls"
                            style={{ backgroundImage: "url(/assets/img/bg-s1.jpg)" }}
                        >
                            <div className="service-box-about">
                                <img src="/assets/img/se-4.png" alt="img" className="img-fluid" />
                                <h4>Islamic Education</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a
                                    purus vitae neque tincidunt bibendum. Nam risus quam, rutrum non
                                    urna nec.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-md-3 px-0 bg-fulls"
                            style={{ backgroundImage: "url(/assets/img/bg-s1.jpg)" }}
                        >
                            <div className="service-box-about">
                                <img src="/assets/img/se-1.png" alt="img" className="img-fluid" />
                                <h4>Training</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a
                                    purus vitae neque tincidunt bibendum.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-md-3 px-0 bg-fulls"
                            style={{ backgroundImage: "url(/assets/img/bg-s1.jpg)" }}
                        >
                            <div className="service-box-about">
                                <img src="/assets/img/se-2.png" alt="img" className="img-fluid" />
                                <h4>Baby Sitters</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a
                                    purus vitae neque neque tincidunt bibendum.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-md-3 px-0 bg-fulls"
                            style={{ backgroundImage: "url(/assets/img/bg-s1.jpg)" }}
                        >
                            <div className="service-box-about border-0">
                                <img src="/assets/img/se-3.png" alt="img" className="img-fluid" />
                                <h4>In-House Chefs</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a
                                    purus vitae neque tincidunt bibendum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="py-5 title-styled">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 p-0">
                            <div
                                className="full-height-img full-img-shade fullgreen"
                                style={{ backgroundImage: 'url("/assets/img/ab-2.jpg")' }}
                            >
                                <div className="center-content text-center">
                                    <h2 className="text-white">Our Community</h2>
                                    <p className="text-white">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                        ac felis ut magna tristique maximus dignissim id orci. In ac
                                        turpis ac tellus accumsan tempor. Praesent varius orci a est
                                        mollis, sed cursus eros aliquet.
                                    </p>
                                    <button className="btn btn-primary btn-1">
                                        Explore More <i className="fa fa-arrow-circle-right ms-2" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 position-relative">
                            <div className="center-content text-center">
                                <h2>This is WithSabr</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac
                                    felis ut magna tristique maximus dignissim id orci. In ac turpis
                                    ac tellus accumsan tempor.
                                </p>
                                <p>
                                    Praesent varius orci a est mollis, sed cursus eros aliquet. elis
                                    ut magna tristique maximus dignissim id orci.
                                </p>
                                <button className="btn btn-primary btn-1">
                                    Explore More <i className="fa fa-arrow-circle-right ms-2" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer />
        </>

    )
}
