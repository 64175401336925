import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AuthLayout from './AuthLayout'
import { useDispatch } from 'react-redux'

function WelcomeScreen() {
    const [userRole, setUserRole] = useState(null)
    const [showError, setShowError] = useState(false)
    const navigate = useNavigate()

    return (
        <AuthLayout>
            <div className="login-form-area welcomescreen px-3 px-lg-5">
                <h2>Welcome to WithSabr </h2>
                <p className="mb-0 mb-md-2 lead">
                    {/* Are you seeking a service?
                    <br />
                    or Would you like to provide your services */}
                    Are you a Service Provider or Service Seeker?
                </p>
                <div className="py-3 px-lg-5">
                    <div className="mt-lg-4 mt-3 mb-4 mb-lg-5 pb-2 code-field d-flex gap-3 justify-content-center">
                        <button
                            type="submit"
                            className={`btn btn-primary ${userRole == 'vendor' ? 'btn-1' : 'btn-3'} btn-radius btn-sm my-2 my-sm-4`}
                            onClick={() => setUserRole('vendor')}
                        >
                            Service Provider
                        </button>
                        <button
                            type="submit"
                            className={`btn btn-primary ${userRole == 'customer' ? 'btn-1' : 'btn-3'} btn-radius btn-sm my-2 my-sm-4`}
                            onClick={() => setUserRole('customer')}
                        >
                            Service Seeker
                        </button>
                    </div>
                    {showError && <span className='text-danger'>Please select one!</span>}
                    <button
                        type="submit"
                        className="btn btn-primary btn-1 d-block w-100 my-4"
                        onClick={() => {
                            if (!userRole) setShowError(true)
                            else navigate('/signup', { state: { userRole: userRole } })
                        }}
                    >
                        Continue
                    </button>
                </div>
            </div>

        </AuthLayout>
    )
}

export default WelcomeScreen
