import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthLayout from './AuthLayout';
import apiClient from '../../../shared/apiClient';
import toast from 'react-hot-toast';
import { setSessionItem } from '../../../shared/lsConfig';
import { useDispatch } from 'react-redux';
import { loginTrue } from '../../../redux/reducers/authSlice';

function UpdatePassword() {
    const inputRefs = useRef([]);

    const [loading, setLoading] = useState(false)
    const [currentPassword, setCurrentPassword] = useState(null)
    const [passwordMatch, setPasswordMatch] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [errors, setErrors] = useState()

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const prevState = useLocation().state

    useEffect(() => {
        if (!prevState) navigate('/')
    }, [prevState])

    const handleSignup = (e) => {
        e.preventDefault();
        setErrors(null)
        const formData = new FormData(e.target);

        const data = Object.fromEntries(formData.entries())
        const otp = Object.keys(data)?.filter(t => t !== "password" && t !== "confirm_password")?.map(key => data[key]).join("")

        const payload = {
            email: prevState?.email,
            otp,
            password: data?.password
        }
        setLoading(true)
        apiClient.post('/Auth/UpdatePassword', payload)
            .then((res) => {
                setLoading(false)
                setSessionItem('auth_token', res.data?.token)
                setSessionItem('refresh_token', res.data?.refreshToken)
                toast.success(res.data?.message)
                dispatch(loginTrue())
                navigate('/')
            }).catch(err => {
                if (err.response?.data?.errors) setErrors(err.response?.data?.errors)
                if (err.response.data?.message) setErrors(err.response?.data?.message)
                setLoading(false)
            })
    }

    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
    const validation = (errors = []) => {
        if (errors.length > 0) {
            return errors.map((err, index) => <p key={index} className='text-danger'>{err}</p>)
        }
    }

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && !e.target.value) {
            inputRefs.current[index - 1].focus();
        }
    };
    const resetInputs = () => {
        inputRefs.current.forEach(input => {
            if (input) input.value = "";
        });
    };
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('Text');

        if (pastedText.length <= inputRefs.current.length) {
            pastedText.split('').forEach((char, i) => {
                inputRefs.current[i].value = char;
                if (i < inputRefs.current.length - 1) {
                    inputRefs.current[i + 1].focus();
                }
            });
        }
    };
    return (
        <AuthLayout>
            <div className="login-form-area px-3 px-lg-5">
                <h2>Update Password</h2>
                <p className="pt-4 mb-0 mb-md-2">
                    Please enter your new password below!
                </p>

                {prevState?.message && <p className="pt-4 mb-0 mb-md-2 text-success">
                    {prevState?.message}
                </p>}
                <form className="py-3 px-lg-5" onSubmit={handleSignup}>
                    <div className="my-4">
                        <input
                            type="text"
                            id="nameemail"
                            name='email'
                            className="form-control f-0"
                            placeholder="Enter email "
                            required
                            disabled
                            value={prevState?.email}
                        />
                    </div>
                    <div className="mt-3 mb-4 pb-2 code-field d-flex gap-2 justify-content-center">
                        {Array(6).fill().map((_, index) => (
                            <input
                                key={index}
                                type="text"
                                className="form-control f-0 code-section blanked"
                                maxLength={1}
                                name={index}
                                ref={el => inputRefs.current[index] = el}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                                onPaste={index === 0 ? handlePaste : null}
                            />
                        ))}
                    </div>
                    <div className="my-4 icon-over" style={{ textAlign: 'left' }}>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="form-control f-0"
                            placeholder="Password"
                            name='password'
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                        <i
                            className={showPassword ? "fa fa-eye-slash icon-over" : "fa fa-eye icon-over"}
                            id="togglePassword1"
                            role='button'
                            onClick={() => setShowPassword(!showPassword)}
                        />
                        {errors?.Password && validation(errors?.Password)}
                    </div>
                    <div className="m4-4 icon-over" style={{ textAlign: 'left' }}>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control f-0"
                            id="confirmpass"
                            placeholder="Confirm Password"
                            name='confirm_password'
                            required
                            onChange={(e) => {
                                if (e.target.value === currentPassword) setPasswordMatch(true)
                                else setPasswordMatch(false)
                            }}
                        />
                        <i
                            className={showConfirmPassword ? "fa fa-eye-slash icon-over" : "fa fa-eye icon-over"}
                            id="togglePassword"
                            role='button'
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                        {!passwordMatch && <p className='text-danger'>The new password that you entered do not match!</p>}

                    </div>
                    <div className='mt-2' style={{ textAlign: 'left' }}>
                        {typeof errors !== "object" && <p className='text-danger'>{errors}</p>}
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary btn-1 d-block w-100 my-4"
                    >
                        {loading ? loader : "Update Password"}
                    </button>
                </form>
            </div>
        </AuthLayout>
    )
}


export default UpdatePassword
