import React, { useEffect, useState } from 'react'
import CustomerHeader from '../Header'
import Banner from './Banner'
import Category from './Category'
import Seller from './Seller'
import Portfolio from './Portfolio'
import Question from './Question'
import Footer from '../Footer'
import apiClient from '../../../shared/apiClient'
import toast from 'react-hot-toast'
import { getServices } from '../../../redux/reducers/homeSlice'
import { useDispatch, useSelector } from 'react-redux'

function Customer() {

    const { services } = useSelector(state => state.home)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getServices())
    }, [])

    return (
        <>
            <CustomerHeader />
            <Banner services={services} />
            <Category services={services} />
            <Seller />
            <Portfolio />
            <Question />
            <Footer services={services} />
        </>
    )
}

export default Customer
