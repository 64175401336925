import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../shared/apiClient';

export const getServices = createAsyncThunk(
    'home/fetchServices',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiClient.get('/Services/GetServices');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const searchCity = createAsyncThunk(
    'home/searchCity',
    async ({ searchText, countryName, pageNumber, pageSize }, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(
                `BusinessInfo/SearchCities?searchText=${searchText}&pageNumber=${pageNumber}&pageSize=${pageSize}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);



export const getCities = createAsyncThunk(
    'home/fetchCities',
    async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(`BusinessInfo/GetCities?pageNumber=${page}&pageSize=${limit}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const homePageSlice = createSlice({
    name: 'homepage',
    initialState: {
        services: [],
        cities: [],
        sellers: [],
        searchCities: [],
        vendors: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getServices.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getServices.fulfilled, (state, action) => {
                state.loading = false;
                state.services = action.payload.serviceList;
            })
            .addCase(getServices.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        builder
            .addCase(getCities.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCities.fulfilled, (state, action) => {
                state.loading = false;
                state.cities = action.payload.cities;
            })
            .addCase(getCities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });


        builder
            .addCase(searchCity.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchCity.fulfilled, (state, action) => {
                state.loading = false;
                state.searchCities = action.payload.cities;
            })
            .addCase(searchCity.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default homePageSlice.reducer;
