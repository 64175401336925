import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AuthLayout from './AuthLayout'
import apiClient from '../../../shared/apiClient'

function ForgetPassword() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries())
        setLoading(true)
        apiClient.post('/Auth/ForgetPassword', data).then((res) => {
            setLoading(false)
            navigate(`/update-password`, { state: { message: res.data?.message, email: data.email } })
        })
    }
    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
    return (
        <AuthLayout>
            <div className="login-form-area px-3 px-lg-5">
                <h2>Forget Password</h2>
                <p className="pt-4 mb-0 mb-md-2">
                    Please enter your registered email or username.
                </p>
                <form onSubmit={handleSubmit} className="py-3 px-lg-5">
                    <div className="my-4">
                        <input
                            type="email"
                            id="nameemail"
                            name='email'
                            required
                            className="form-control f-0"
                            placeholder="Enter email or username"
                        />
                    </div>
                    <div className="m4-4 icon-over"></div>
                    <button
                        type="submit"
                        className="btn btn-primary btn-1 d-block w-100 my-4"
                    >
                        {loading ? loader : "Send Email"}
                    </button>
                </form>
            </div>
        </AuthLayout>
    )
}

export default ForgetPassword
