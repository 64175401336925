import React, { useState } from 'react'
import Footer from '../Footer'
import CustomerHeader from '../Header'
import { useLocation, useNavigate } from 'react-router-dom'
import apiClient from '../../../shared/apiClient'
import toast from 'react-hot-toast'

function BackgroundCheck() {
    const payload = useLocation().state
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [error, setError] = useState();

    const [formData, setFormData] = useState({
        AuthorizationDate: '',
        BackgroundCheckConsent: '',
        SocialSecurityNumber: '',
        CurrentAddress: '',
        DateOfBirth: '',
        ConvictedOfCrime: '',
        CrimeDetails: '',
        BackgroundCheckConsent: '',
    })

    const handleSave = (e) => {
        e.preventDefault();
        const data = {
            ...payload, ...formData,
            BackgroundCheckConsent: true,
        }
        setLoading(true)
        apiClient.post('/Auth/VendorAccountRequest', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((res) => {
            setLoading(false)
            toast.success(res.data?.message)
            navigate('/')
        }).catch((err) => {
            setLoading(false)
            if (err.response?.data?.errors) {
                setErrors(err.response?.data?.errors)
            }
        })
    }


    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
    return (
        <>
            <CustomerHeader />
            <section
                className="banner-background title-styled"
                style={{ backgroundImage: "url(/assets/img/banner-2.png)" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-4 text-center">
                            <h2 className="text-color1">Your Service Profile is complete!</h2>
                            <p>
                                The next step is to schedule your "SabrSafe" Screening Interview and
                                fill out the required background check details below.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="title-styled main-vendor">
                <form onSubmit={handleSave}>
                    <section className="py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">
                                            Social Security Number*
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your social security number"
                                            name='SecurityNumber'
                                            required
                                            onChange={(e) => setFormData({ ...formData, SocialSecurityNumber: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Current Address</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your address"
                                            name='CurrentAddress'
                                            required
                                            onChange={(e) => setFormData({ ...formData, CurrentAddress: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label htmlFor="name" className="form-label fv-label">
                                            Date of Birth
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control f-v calander-icon"
                                            placeholder="DD/MM/YYYY"
                                            name='BirthDate'
                                            // required
                                            onChange={(e) => setFormData({ ...formData, DateOfBirth: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">
                                            Have you been convicted of any crime?
                                        </label>
                                        <div className="d-flex">
                                            <label className="align-items-center d-flex me-2">
                                                <input
                                                    type="radio"
                                                    className="option-input radio"
                                                    name="example"
                                                    defaultChecked=""
                                                    value={true}
                                                    required
                                                    onChange={(e) => setFormData({ ...formData, ConvictedOfCrime: e.target.checked })}
                                                />
                                                Yes
                                            </label>
                                            <label className="align-items-center d-flex">
                                                <input
                                                    type="radio"
                                                    className="option-input radio"
                                                    name="example"
                                                    value={false}
                                                    required
                                                    onChange={(e) => setFormData({ ...formData, ConvictedOfCrime: e.target.value })}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">
                                            If yes, please provide details
                                        </label>
                                        <textarea
                                            className="form-control f-v txtarea1"
                                            placeholder="Write here"
                                            defaultValue={""}
                                            name='detailsss'
                                            required
                                            onChange={(e) => setFormData({ ...formData, CrimeDetails: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="py-md-5 py-3">
                            <div className="heading-bordered mb-4 pb-1">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Authorization and Consent</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 my-md-4 my-3">
                                        <div className="fv-outer">
                                            <label className="form-label fv-label">
                                                I authorize the background check and consent to the use of my
                                                personal information for this purpose
                                            </label>
                                            <div className="d-flex">
                                                <label className="align-items-center d-flex me-2">
                                                    <input
                                                        type="radio"
                                                        className="option-input radio"
                                                        name="example1"
                                                        defaultChecked=""
                                                    />
                                                    Yes
                                                </label>
                                                <label className="align-items-center d-flex">
                                                    <input
                                                        type="radio"
                                                        className="option-input radio"
                                                        name="example1"
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-md-4 my-3">
                                        <div className="fv-outer">
                                            <label htmlFor="name" className="form-label fv-label">
                                                Select Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control f-v calander-icon"
                                                placeholder="DD/MM/YYYY"

                                                name='authorizeDate'
                                                // required
                                                onChange={(e) => setFormData({ ...formData, AuthorizationDate: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-5">
                                        <button type="submit" className="btn btn-primary btn-1">
                                            {loading ? loader : <>
                                                Submit <i className="fa fa-arrow-circle-right ms-2" />
                                            </>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </form>
            </section>
            <Footer />
        </>
    )
}

export default BackgroundCheck
