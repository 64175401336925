import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getServices } from '../../redux/reducers/homeSlice';

export default function Footer() {

    const { services } = useSelector(state => state.home)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getServices())
    }, [])

    return (
        <footer className="footer-main">
            <div className="container">
                <div className="row align-items-center py-4 text-center">
                    <div className="col-sm-6 py-2 text-sm-start">
                        <a className="d-inline-block" href="index.html">
                            <img
                                src={'/assets/img/logo-light.png'}
                                alt="image"
                                height={70}
                                width={200}
                                className="img-fluid f-logo"
                            />
                        </a>
                    </div>
                    <div className="col-sm-6 py-2">
                        <ul className="social-list text-sm-end mt-sm-0 mt-2">
                            <li>
                                <a href="#" target="_blank" className="fa fa-facebook" />
                            </li>
                            <li>
                                <a href="#" target="_blank" className="fa fa-instagram" />
                            </li>
                            <li>
                                <a href="#" target="_blank" className="fa fa-linkedin" />
                            </li>
                            <li>
                                <a href="#" target="_blank" className="fa fa-twitter" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-top py-4">
                    <div className="row">
                        <div className="col-lg-5 py-2">
                            <h4 className="text-uppercase">Why choose WithSabr?</h4>
                            <p className="my-4">
                                At WithSabr, we offer a wide range of safe and reliable home
                                services for Muslims, by Muslims. Our 'SabrSafe" Screening and
                                Criminal Background Check provides security that you can rely on.
                            </p>
                            <h4 className="text-uppercase pt-3 pt-md-4">Contact Us</h4>
                            <p className="my-4">
                                If you have any questions, please <br />
                                contact us at:{" "}
                                <a href="mailto:Support@WithSabr.com">Support@WithSabr.com</a>
                            </p>
                        </div>
                        <div className="col-md-4 py-2">
                            <div className="ps-lg-5 ms-lg-3">
                                <h4>Home</h4>
                                <ul>
                                    <li>
                                        <Link to="/login">Login</Link>
                                    </li>
                                    <li>
                                        <Link to="/welcome-screen">Sign-up</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 py-2">
                            <h4>Categories</h4>
                            <ul>
                                {services?.map((service, index) => (
                                    <li key={index}>
                                        <a href="#">{service?.ServiceName}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col-sm-12">
                        <p className="mb-0 my-1 copyright">
                            © 2024 WithSabr, All rights are reserved
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
