import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function AuthLayout({ children }) {
    const { pathname } = useLocation();

    const routes = ['/signup', '/welcome-screen']

    return (
        <section className="login-area h-100">
            <div className="container title-styled text-center h-100">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-md-6 py-5 d-md-block d-none">
                        <div className="bg-login">
                            <img src={'/assets/img/image-1.svg'} className="img-fluid mb-5" alt="Image" />
                            <h2 className="text-color1">New Here!</h2>
                            <span>
                                <Link to={routes.includes(pathname) ? '/login' : "/welcome-screen"}>
                                    {routes.includes(pathname) ? "Login" : "Sign Up"}
                                </Link> to join our community and explore what we
                                have to offer.
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6 py-4 py-md-5">
                        {children}

                        <div className="d-block d-md-none pt-5 px-3">
                            <Link to={routes.includes(pathname) ? '/login' : "/welcome-screen"}>
                                {routes.includes(pathname) ? "Login" : "Sign Up"}
                            </Link> to join our community and explore what we have
                            to offer.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
